<template>
  <v-app class="pt-15">
    <customize-header homeRouter="/ARcreativeMarketing/home"
                      homeTitle="AR创意运营"
                      :links="links"></customize-header>
    <router-view></router-view>
    <customize-suspendBtn></customize-suspendBtn>
    <customize-footer></customize-footer>
  </v-app>
</template>

<script>
export default {
  name: 'AR',
  data: () => ({
    links: [
      {
        title: "首页",
        router: "/ARcreativeMarketing/home",
        children: []
      },
      {
        title: "解决方案",
        router: "/ARcreativeMarketing/ARSolution",
        children: []
      },
      // {
      //   title: "案例",
      //   router: "/ARcreativeMarketing/ARcase",
      //   children: []
      // },
      {
        title: "招商加盟",
        router: "/ARcreativeMarketing/ARInvestmentPromotionAgency",
        children: []
      }
    ],
  }),
};
</script>
<style lang="scss" scoped>

</style>